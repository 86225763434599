<template>
  <section name="modelo">
    <v-card elevation="2" rounded class="ma-8 pa-4">
      <v-row>
        <v-col cols="12">
          <Crud
            title="Gestão de Modelos"
            buttonAddItemText="Novo Modelo"
            searchLabelText="Pesquisar na lista"
            :searchTextField.sync="searchTextField"
            :formTitle="nameForm"
            :callBackModalCancelar="fechar"
            :dialog.sync="dialog"
            :callBackModalSalvar="salvar"
            :dialogDelete.sync="dialogDelete"
          >
            <template slot="data-content">
              <v-row class="mt-5">
                <!-- FILTRO CAMPOS -->
              </v-row>
            </template>
            <template slot="data-table">
              <v-data-table
                :headers="headers"
                :items="modelos"
                class="elevation-5"
                :loading="loadingTable"
              >
                <!-- ACTIONS -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingVisualizar[`${item.id}`]"
                      >
                        <v-icon
                          class="actions"
                          @click="
                            $router.push(
                              `/avd-modelos/formulario/${item.uuid}/visualizar`
                            )
                          "
                        >
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingEditar[`${item.id}`]"
                      >
                        <v-icon
                          class="actions"
                          @click="
                            $router.push(
                              `/avd-modelos/formulario/${item.uuid}/editar`
                            )
                          "
                        >
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingDelete[`${item.id}`]"
                      >
                        <v-icon class="actions" @click="remover(item)">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-switch
                    class="mt-0 pt-0"
                    v-model="item.status"
                    @click="mudarStatus(item)"
                    :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                    color="success"
                    value="A"
                    hide-details
                    :loading="loadingStatus[`${item.id}`]"
                  ></v-switch>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ item.created_at | dateFormatBr() }}
                </template>
              </v-data-table>
            </template>
            <!--------------- MODAL DE DELETE --------------------------------------------- -->
            <template slot="modalDelete/titleModal">
              Excluir o registro?
            </template>
            <template slot="modalDelete/contentModal">
              <p>
                Atenção! Você está prestes a realizar uma ação que não pode ser
                desfeita.
              </p>
              <p>Você realmente deseja excluir esse registro?</p>
            </template>
            <template slot="modalDelete/actionsModal">
              <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
              <v-btn
                :loading="loadingButtonDelete"
                color="error"
                @click="remover()"
              >
                Excluir
              </v-btn>
              <v-spacer class="d-none d-md-block"></v-spacer>
            </template>
            <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
            <template slot="contentModalCrud">
              <v-container fluid>
                <v-row class="ma-3" wrap>
                  <!-- Titulo -->
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      ref="titulo"
                      label="Título do modelo"
                      placeholder="Escreva o título do modelo"
                      required
                      outlined
                      dense
                      v-model="modelo.titulo"
                      data-vv-as="titulo"
                      persistent-hint
                      :hint="errors.first('titulo')"
                      :error="errors.collect('titulo').length ? true : false"
                      v-validate="'required|min:3'"
                      data-vv-validate-on="change"
                      id="titulo"
                      name="titulo"
                    ></v-text-field>
                  </v-col>
                  <!-- Responsavel -->
                  <v-col cols="12" sm="12" md="6" lg="6">
                    <v-select
                      label="Responsável"
                      :items="responsaveis"
                      item-text="nome"
                      item-value="id"
                      placeholder="Selecione um responsável"
                      outlined
                      dense
                      required
                      v-model="modelo.id_responsavel"
                      data-vv-as="responsável"
                      persistent-hint
                      :hint="errors.first('responsavel')"
                      :error="
                        errors.collect('responsavel').length ? true : false
                      "
                      v-validate="'required'"
                      data-vv-validate-on="change"
                      id="responsavel"
                      name="responsavel"
                    ></v-select>
                  </v-col>
                  <!-- Descricao -->
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      ref="descricao"
                      label="Descrição"
                      outlined
                      dense
                      v-model="modelo.descricao"
                      data-vv-as="descrição"
                      persistent-hint
                      :hint="errors.first('descricao')"
                      :error="errors.collect('descricao').length ? true : false"
                      v-validate="'required'"
                      data-vv-validate-on="change"
                      id="descricao"
                      name="descricao"
                    ></v-textarea>
                  </v-col>
                  <!-- Range -->
                  <v-col sm="12" md="12" cols="12">
                    <v-slider
                      class="mx-2"
                      v-model="modelo.range"
                      step="1"
                      min="4"
                      max="10"
                      thumb-label
                      ticks
                      persistent-hint
                      hint="Defina o grau máximo da sua escala"
                      label="Tamanho da escala"
                      track-color="orange darken-3"
                    ></v-slider>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </Crud>
        </v-col>
      </v-row>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";

export default {
  name: "ModeloAvd",
  components: {
    Crud,
  },
  data: () => ({
    logotipo: logo,
    nameForm: "Novo Modelo",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    modelo: {
      id_empresa: "",
      id_responsavel: "",
      titulo: "",
      descricao: "",
      range: 4,
      padrao: "personalizado",
      status: "A",
    },
    SelectItensStatus: [
      { text: "Ativo", value: "A" },
      { text: "Inativo", value: "I" },
    ],
    searchTextField: "",
    dialog: false,
    indexEdicao: -1,
    loadingTable: false,
    loadingVisualizar: [],
    loadingEditar: [],
    loadingDelete: [],
    loadingStatus: [],
    headers: [
      { text: "Título", align: "start", value: "titulo" },
      { text: "Responsável", value: "responsavel.nome" },
      { text: "Padrão", value: "padrao" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    rating: 0.0,
    dialogDelete: false,
    loadingButtonDelete: false,
    item: [],
  }),

  created() {
    this.$store.dispatch("avd/buscarModelosAvd");
  },

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    formTitle() {
      return this.indexEdicao === -1 ? "Cadastrar modelo" : "Editar modelo";
    },
    modelos: {
      get() {
        return this.$store.getters["avd/getModelos"];
      },
      set(value) {
        return value;
      },
    },
    responsaveis: {
      get() {
        return this.$store.getters["avd/getResponsaveis"];
      },
    },
    comportamentos: {
      get() {
        return this.$store.getters["avd/getComportamentos"];
      },
      set(value) {
        return value;
      },
    },
    competencias: {
      get() {
        return this.$store.getters["avd/getCompetencias"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["avd/getLoading"];
      },
      set(value) {
        return value;
      },
    },
  },

  methods: {
    salvar() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loadingTable = true;
          this.dialog = false;
          let url =
            this.indexEdicao === -1
              ? "/avd-modelos"
              : "/avd-modelos/" + this.modelo.uuid;
          let method = this.indexEdicao === -1 ? "POST" : "PUT";
          let sucesso =
            this.indexEdicao === -1
              ? "Modelo cadastrado com sucesso."
              : "Modelo atualizado com sucesso.";
          let erro =
            this.indexEdicao === -1
              ? "Erro ao salvar modelo."
              : "Erro ao atualizar modelo.";

          this.modelo.padrao = "personalizado";
          let modeloItem = this.modelo;
          modeloItem._method = method;
          modeloItem.url = url;
          this.$store
            .dispatch("avd/salvarModelo", modeloItem)
            .then((response) => {
              this.indexEdicao === -1
                ? this.modelos.push(response)
                : Object.assign(this.modelos[this.indexEdicao], this.modelo);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                cor: "green",
                mensagem: sucesso,
                duracao: 3000,
              });
              this.indexEdicao === -1
                ? this.$router.push(
                    `/avd-modelos/formulario/${response.uuid}/editar`
                  )
                : this.$router.push(
                    `/avd-modelos/formulario/${modeloItem.uuid}/editar`
                  );
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                cor: "red",
                mensagem: erro,
                duracao: 3000,
              });
            })
            .finally(() => {
              this.fechar();
            });
        } else {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "red",
            mensagem: "Preencha todo formulário antes de salvar!",
            duracao: 3000,
          });
        }
      });
    },
    editar(item) {
      this.indexEdicao = this.modelos.indexOf(item);
      this.modelo = Object.assign({}, item);
      this.dialog = true;
    },
    fechar() {
      this.loadingTable = false;
      this.dialog = false;
      this.modelo = {
        id_empresa: "",
        id_responsavel: "",
        titulo: "",
        descricao: "",
        range: 4,
        padrao: "personalizado",
        status: "A",
      };
    },
    mudarStatus(item) {
      this.loadingStatus[item.id] = true;
      this.loadingTable = true;

      this.indexEdicao = this.modelos.indexOf(item);
      this.modelo = Object.assign({}, item);
      this.modelo.status =
        this.modelo.status == null ? "I" : this.modelo.status;
      let modeloItem = this.modelo;
      modeloItem._method = "PUT";
      modeloItem.url = "/avd-modelos/" + this.modelo.uuid;
      this.$store
        .dispatch("empresas/salvarModelo", modeloItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            Object.assign(this.modelos[this.indexEdicao], this.modelo);
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Modelo atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar modelo.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingStatus[item.id] = false;
          this.loadingTable = false;
          this.indexEdicao = -1;
          this.modelo = Object.assign(
            {},
            {
              id_empresa: "",
              id_responsavel: "",
              titulo: "",
              descricao: "",
              range: 4,
              padrao: "personalizado",
              status: "A",
            }
          );
        });
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.modelos.findIndex((el) => el.id == item.id);

        this.$store
          .dispatch("avd/deleteModelo", item.uuid)
          .then((response) => {
            if (response.data === true) {
              this.modelos.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Modelo removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o modelo.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    // DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A" || status == "Ativo") return "green";
      else return "red";
    },
  },
};
</script>

<style scoped></style>
