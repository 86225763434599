import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"crud"}},[_vm._t("sectionInfo"),_c(VCard,[(_vm.title)?_c(VCardTitle,[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.subtitle)}})]):_vm._e(),_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[(
          _vm.hideSearch !== undefined
            ? !_vm.hideSearch
            : _vm.searchTextField !== undefined
            ? true
            : false
        )?_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":"","append-icon":"mdi-text-search","label":_vm.searchLabelText,"single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_vm._t("advancedSearch"),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.btnNovo)?_c(VBtn,{staticClass:"mb-4 mr-3",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.$router.push({ name: _vm.btnNovoRota })}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.btnNovoIcone)+" ")]),_c('span',[_vm._v(_vm._s(_vm.btnNovo))])],1):_vm._e(),_c(VDialog,{attrs:{"persistent":true,"scrollable":_vm.scrollable !== undefined ? _vm.scrollable : true,"max-width":_vm.tamanhoModalCrud !== undefined ? _vm.tamanhoModalCrud : '800px'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(
                _vm.showButtonAddItem !== undefined
                  ? _vm.showButtonAddItem
                  : _vm.callBackModalInsert !== undefined ||
                    _vm.buttonAddItemText !== undefined
                  ? true
                  : false
              )?_c(VBtn,_vm._g(_vm._b({staticClass:"mb-4 me-3",attrs:{"color":"primary","dark":"","large":"","loading":_vm.loadingbuttonAddItem,"disabled":_vm.disabledbuttonAddItem},on:{"click":function($event){_vm.callBackModalInsert !== undefined
                  ? _vm.callBackModalInsert()
                  : null}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" mdi-plus ")]),_c('span',[_vm._v(_vm._s(_vm.buttonAddItemText != "" ? _vm.buttonAddItemText : "Novo Registro"))])],1):_vm._e()]}}]),model:{value:(_vm.dialogInsertUpdateElement),callback:function ($$v) {_vm.dialogInsertUpdateElement=$$v},expression:"dialogInsertUpdateElement"}},[_c(VCard,{staticStyle:{"background-color":"white !important"},attrs:{"loading":_vm.showLoadingModal,"disabled":_vm.showLoadingModal}},[_c(VCardTitle,{staticStyle:{"padding":"0 12px !important"}},[_vm._t("prependTitleModal"),_c('div',{staticClass:"full-width pa-4 pl-5"},[_c('span',{},[_vm._v(_vm._s(_vm.formTitle))])]),_vm._t("appendTitleModal")],2),_c(VDivider),_c(VCardText,{staticClass:"scrollbar",style:('height: ' + _vm.modalCrudheight ? _vm.modalCrudheight : 500 + 'px;')},[_vm._t("contentModalCrud")],2),_c(VDivider),_c(VCardActions,{staticClass:"pt-0 p-0 mt-2 column-reverse-xs justify-end"},[_vm._t("footerModalCrud"),(_vm.callBackModalCancelar !== undefined)?_c(VBtn,{staticClass:"mr-2",attrs:{"color":"secondary","outlined":"","large":"","depressed":"","disabled":_vm.loadingButtonSave !== undefined ? _vm.loadingButtonSave : null},on:{"click":function($event){_vm.callBackModalCancelar !== undefined
                    ? _vm.callBackModalCancelar()
                    : null}}},[_vm._v(" Fechar ")]):_vm._e(),_c(VSpacer,{staticClass:"mt-2 mb-2 d-block d-md-none",attrs:{"vertical":""}}),(_vm.callBackModalSalvar !== undefined)?_c(VBtn,{staticClass:"block-xs full-width-xs",attrs:{"color":"primary","large":"","depressed":"","loading":_vm.loadingButtonSave !== undefined ? _vm.loadingButtonSave : null,"disabled":_vm.loadingButtonSave !== undefined ? _vm.loadingButtonSave : null},on:{"click":function($event){_vm.callBackModalSalvar !== undefined
                    ? _vm.callBackModalSalvar()
                    : null}}},[_vm._v(" Salvar ")]):_vm._e()],2)],1)],1),(_vm.callBackModalExportar !== undefined)?_c(VBtn,{staticClass:"mb-4",attrs:{"color":"secondary","outlined":"","large":""},on:{"click":function($event){_vm.callBackModalExportar !== undefined
              ? _vm.callBackModalExportar()
              : null}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" mdi-tray-arrow-up ")]),_c('span',[_vm._v("Exportar")])],1):_vm._e(),_vm._t("addButtonsArea")],2)],2),_c('div',{staticClass:"hidden-md-and-up"},[_vm._t("data-list"),_c(VSpacer)],2)],1),_c('div',{staticClass:"mt-4 elevation-3"},[_vm._t("data-table")],2),_vm._t("data-content"),_c('div',[_c(VDialog,{attrs:{"persistent":true,"max-width":"300"},model:{value:(_vm.dialogDeleteElement),callback:function ($$v) {_vm.dialogDeleteElement=$$v},expression:"dialogDeleteElement"}},[_c('div',{staticClass:"pa-2"},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._t("modalDelete/titleModal")],2),_c(VCardText,[_vm._t("modalDelete/contentModal")],2),_c(VCardActions,{staticClass:"justify-center"},[_vm._t("modalDelete/actionsModal")],2)],1)],1)])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }